import { ChangeEventHandler, useEffect } from "react";
import { DISPLAY_NAME_CHAR_LIMIT, URL_VALIDATION_REGEX } from "~/utils/consts";
import { RoomTheme } from "~/types/room/types";
import { styled } from "~/ui/style/stitches.config";

const SOCIAL_MEDIA_CHAR_LIMIT = 200;

export const socialMedias = [
  {
    name: "instagram",
    placeholder: "instagram.com/",
  },
  {
    name: "tiktok",
    placeholder: "tiktok.com/@",
  },
  {
    name: "twitter",
    placeholder: "twitter.com/",
  },
  {
    name: "twitch",
    placeholder: "twitch.tv/",
  },

  {
    name: "youtube",
    placeholder: "youtube.com/@",
  },
  {
    name: "facebook",
    placeholder: "facebook.com/",
  },
  {
    name: "website",
    placeholder: "yourhomepage.com",
    dontOverWrite: true,
    regEx: URL_VALIDATION_REGEX,
  },
];

export const SocialMediaIcons = ({
  data,
  theme,
}: {
  data: any;
  theme: RoomTheme;
}) => {
  return (
    <StyledSocialMediaIcons>
      {socialMedias.map((socialMedia) => {
        return (
          data[socialMedia.name] &&
          data[socialMedia.name] !== socialMedia.placeholder && (
            <img
              key={"icon-" + socialMedia.name}
              onClick={() =>
                window.open(
                  `https://${
                    !socialMedia.dontOverWrite ? socialMedia.placeholder : ""
                  }${data[socialMedia.name].split("https://").join("")}`,
                  "_blank"
                )
              }
              className="social-icon"
              src={`/images/socials/${socialMedia.name}-icon${
                theme === "dark" ? "-dark" : ""
              }.png`}
            ></img>
          )
        );
      })}
    </StyledSocialMediaIcons>
  );
};

const StyledSocialMediaIcons = styled("div", {
  display: "flex",
  marginLeft: "8px",
  marginBottom: "12px",
  "& img": {
    cursor: "pointer",
    width: "25px",
    marginRight: "8px",
    transition: "opacity 0.2s ease-in-out",
    "&:hover": {
      opacity: 0.7,
    },
  },
});

export const SocialMediaInputs = ({
  handleSocialInputChange,
  data,
  theme,
}: {
  handleSocialInputChange: Function;
  data: any;
  theme: RoomTheme;
}) => {
  return (
    <StyledSocialMediaInputs className="input-container" Theme={theme}>
      <div className="input-headline">Your internet presence</div>
      {socialMedias.map((socialMedia) => {
        return (
          <div
            key={socialMedia.name}
            className="input-container"
            placeholder={socialMedia.placeholder}
          >
            <input
              style={{
                backgroundImage: `url(/images/socials/${socialMedia.name}-icon${
                  theme === "dark" ? "-dark" : ""
                }.png)`,
              }}
              spellCheck={false}
              id={`input-${socialMedia.name}`}
              type="text"
              className={`empty-${
                data[socialMedia.name] === "" ||
                data[socialMedia.name] === socialMedia.placeholder
              }`}
              name={socialMedia.name}
              placeholder={socialMedia.placeholder}
              maxLength={socialMedia.regEx ? 1000 : SOCIAL_MEDIA_CHAR_LIMIT}
              pattern={socialMedia.regEx ? socialMedia.regEx : undefined}
              value={
                socialMedia.dontOverWrite
                  ? data[socialMedia.name]
                  : socialMedia.placeholder + data[socialMedia.name]
              }
              onChange={(e) => {
                handleSocialInputChange(e, socialMedia);
              }}
              onInvalid={(e) => e.preventDefault()}
            ></input>
            {socialMedia.regEx && (
              <p className="error-message">Please enter a valid link</p>
            )}
          </div>
        );
      })}
    </StyledSocialMediaInputs>
  );
};

const StyledSocialMediaInputs = styled("div", {
  "& input": {
    paddingLeft: "33px !important",
    backgroundPosition: "8px calc(50%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "20px",

    "@mobile": {
      fontSize: "15px",
    },
  },
  variants: {
    Theme: {
      light: {},
      dark: {},
    },
  },
});
