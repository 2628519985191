import { RoomState } from "~/types/room/types";
import { localState } from "~/state/state";

import { Guidebook } from "../room/Guidebook";
import { BottomBar } from "./BottomBar";
import { SpreadNav } from "./SpreadNav";
import { Toolbar } from "./Toolbar";
import { useStorage } from "~/state/liveblocks.config";
import { useSnapshot } from "valtio";
import { HelpButtonSmall } from "../components/HelpButton";
import { ScreenshotButton } from "../room/Screenshot";
import { ReadingNotes } from "../room/ReadingNotes";

export const RoomMenus = () => {
  const state = useStorage((root) => root.state);

  const { isGuidebookOpen, isOnMobile, lastViewedCard } =
    useSnapshot(localState);
  return (
    <div
      style={{ WebkitUserSelect: "none" }}
      onClick={(e) => e.stopPropagation()}
    >
      <Toolbar />
      <div
        className="left-room-menus"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "12px",
        }}
      >
        <Guidebook />
        <Guidebook isReadingNotes={true} />
      </div>
      <ScreenshotButton />
      {state === RoomState.Draw && <SpreadNav />}
      <BottomBar />
    </div>
  );
};
