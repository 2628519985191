import html2canvas from "html2canvas";
import { Button } from "../components/Button";
import { styled } from "../style/stitches.config";
import { useState } from "react";
import { useStorage } from "~/state/liveblocks.config";
import { shallow } from "@liveblocks/client";
import { DeckCard } from "~/types/room/types";

export const ScreenshotButton = () => {
  const takeScreenshot = () => {
    // clone node
    const spreadCopy = document.querySelector(
      "#spread"
    ) as HTMLElement as HTMLElement;

    const showForScreenshot = document.querySelectorAll(
      ".show-for-screenshot"
    ) as NodeListOf<HTMLElement>;

    showForScreenshot.forEach((el) => {
      el.style.opacity = "1";
    });

    html2canvas(spreadCopy, {
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const link = document.createElement("a");
      link.download = "screenshot.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
      showForScreenshot.forEach((el) => {
        el.style.opacity = "0";
      });
    });
  };

  return (
    <StyledScreenshotButton>
      <Button darkHover onClick={takeScreenshot} className="screenshot-button">
        Take photo
      </Button>
    </StyledScreenshotButton>
  );
};

const StyledScreenshotButton = styled("div", {
  position: "absolute",
  bottom: "6px",
  right: "13px",
  zIndex: 100,

  "& .screenshot-button": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "13px",
    padding: "8px 22px",
    gap: "8px",
    borderRadius: "12px",
  },

  "& img": {
    width: "20px",
    height: "20px",
  },
});
